/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Text, Button, Fullmap, FullmapWrap, FullmapCover } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kontakt"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-14iqz1p --style2 --full pb--60 pt--60" name={"v01kxbrzml"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/e7b2954f23fe413f995416cf8b8089f9_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/e7b2954f23fe413f995416cf8b8089f9_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/e7b2954f23fe413f995416cf8b8089f9_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/e7b2954f23fe413f995416cf8b8089f9_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/e7b2954f23fe413f995416cf8b8089f9_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/e7b2954f23fe413f995416cf8b8089f9_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/e7b2954f23fe413f995416cf8b8089f9_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/e7b2954f23fe413f995416cf8b8089f9_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s3" anim={"2"} animS={"3"}>
              
              <Title className="title-box fs--86 title-box--invert" content={"Kontakt<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" name={"paticka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex --center el--3 flex--top" anim={""} animS={"2"} style={{"maxWidth":""}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box subtitle-box--left fs--26 swpf--uppercase mt--06 pl--0" content={"<span style=\"color: rgb(255, 255, 255);\">Vinařství Knápek</span>"}>
              </Subtitle>

              <Text className="text-box text-box--left mt--02" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-dominant);\">s.r.o.</span>"}>
              </Text>

              <Text className="text-box text-box--left" style={{"maxWidth":""}} content={"<span style=\"color: rgb(255, 255, 255);\">Pouzdřanská 340<br>693 01 Strachotín<br>IČO 05868751<br>DIČ CZ05868751</span><br>"}>
              </Text>

              <Text className="text-box text-box--left" style={{"maxWidth":366}} content={"<span style=\"color: rgb(255, 255, 255);\"><a href=\"https://tvorba-stranek-brno.cz/\">Tvorba webových stránek Brno</a></span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":485}}>
              
              <Text className="text-box text-box--left" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-dominant);\">Vinařství — Libor Knápek</span>"}>
              </Text>

              <Subtitle className="subtitle-box subtitle-box--left ls--0 mt--06" content={"<span style=\"color: rgb(255, 255, 255);\">(420) 777 574 207</span><span style=\"color: var(--color-dominant);\"><br></span>"}>
              </Subtitle>

              <Text className="text-box text-box--left" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-dominant);\">Ubytování — Jana Knápková</span>"}>
              </Text>

              <Subtitle className="subtitle-box subtitle-box--left ls--0 mt--06" content={"<span style=\"color: rgb(255, 255, 255);\">(420) 608 423 866</span><span style=\"color: var(--color-dominant);\"><br></span>"}>
              </Subtitle>

              <Text className="text-box text-box--left" style={{"maxWidth":366}} content={"<span style=\"color: rgb(255, 255, 255);\"><a href=\"mailto:info@ubytovani-strachotin-knapek.cz\">info@ubytovani-strachotin-knapek.cz</a></span><br>"}>
              </Text>

              <Text className="text-box text-box--left mt--0" style={{"maxWidth":366}} content={"<span style=\"color: rgb(255, 255, 255);\"><a href=\"https://www.facebook.com/VinarstviKnapek/\">https://www.facebook.com/VinarstviKnapek/</a></span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Button className="btn-box btn-box--sbtn2 btn-box--cColor2" use={"page"} href={"/"} content={"Úvod"}>
              </Button>

              <Button className="btn-box btn-box--sbtn2 btn-box--cColor2 mt--08" use={"page"} href={"/penzion"} content={"Penzion"}>
              </Button>

              <Button className="btn-box btn-box--sbtn2 btn-box--cColor2 mt--08" use={"page"} href={"/sklep"} content={"Vinní sklep"}>
              </Button>

              <Button className="btn-box btn-box--sbtn2 btn-box--cColor2 mt--08" use={"page"} href={"/penzion#cenik"} content={"Ceník"}>
              </Button>

              <Button className="btn-box btn-box--sbtn2 btn-box--cColor2 mt--08" use={"page"} href={"/kontakt"} content={"Kontakt"}>
              </Button>

              <Text className="text-box text-box--right" style={{"maxWidth":""}} content={"<span style=\"color: rgb(255, 255, 255);\">Jsme plátci DPH.&nbsp;&nbsp;Spisová značka:\tC 98210 vedená u Krajského soudu v Brně</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap className="--style2" name={"zksmpf9goc"}>
          
          <FullmapWrap className="--full">
            
            <FullmapCover zoom={"11"} place={"Pouzdřanská 340, Strachotín"}>
            </FullmapCover>

          </FullmapWrap>

        </Fullmap>


        <Fullmap name={"kufbcwm7nka"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>

      </Layout>
    </ThemeWrapper>
  )
}